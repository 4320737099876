/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
	float: left;
}

.u-float-right {
	float: right;
}

.u-clear {
	clear: both;
}

.u-text {
	&-left {
		text-align: left;
	}

	&-right {
		text-align: right;
	}

	&-center {
		text-align: center;
	}

	&-md {
		&-left {
			@include bp(medium) {
				text-align: left;
			}
		}

		&-right {
			@include bp(medium) {
				text-align: right;
			}
		}

		&-center {
			@include bp(medium) {
				text-align: center;
			}
		}
	}
}
