.b-hero {
	color: $color-brand-1;
	padding-bottom: 112px;
	padding-top: 32px;
	position: relative;
	z-index: 1;

	@include bp-down(large) {
		padding-top: 16px;
		padding-bottom: 48px;
		overflow-x: hidden;
	}

	@include bp(large) {
		font-size: 80%;
	}

	@include bp(xlarge) {
		font-size: 100%;
	}

	&__row {
		@include bp-down(large) {
			height: calc(100vh - 128px);
		}
	}

	&__top {
		padding-top: 51px;
		padding-bottom: 17.5vw;
		transform: translateX(-20%);
	}

	&__bottom {
		transform: translateY(32px);
		text-align: right;
		margin-right: 45px;
	}

	&__top,
	&__bottom {
		font-weight: 500;
		font-size: em(150);
		line-height: 0.8;
		letter-spacing: 0.02em;
		text-transform: uppercase;

		@include bp-down(large) {
			display: none;
		}
	}

	// &__vertical {
	// 	font-style: normal;
	// 	font-weight: 500;
	// 	font-size: em(34);
	// 	line-height: 1.18;
	// 	letter-spacing: -0.01em;
	// 	font-feature-settings: 'tnum' on, 'lnum' on;
	// 	transform: rotate(-90deg);
	// 	// padding-top: 208px;
	// 	position: absolute;
	// 	bottom: 19%;
	// 	left: calc(-3vw + 34px);
	// 	right: 0;
	// 	max-width: 240px;
	// 	z-index: 10;
	// 	@include bp-down(large) {
	// 		max-width: 100%;
	// 		left: 0;
	// 	}
	// }

	&__vertical {
		font-style: normal;
		font-weight: 500;
		font-size: em(34);
		line-height: 1.18;
		letter-spacing: -0.01em;
		font-feature-settings: 'tnum' on, 'lnum' on;
		transform: rotate(180deg);
		writing-mode: vertical-rl;
		max-height: 240px;
		z-index: 10;
		padding-top: 96px;

		@include bp-down(large) {
			max-height: 100%;
			white-space: nowrap;
		}
	}

	&__arrow {
		margin-right: 34px;
		transform: rotate(180deg);
		cursor: pointer;

		@include bp-down(large) {
			margin-right: 0;
			position: absolute;
			top: 0;
		}
	}

	&__image {
		background-image: var(--hero-image);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		@include bp-down(large) {
			height: 100%;
			min-height: 52.8vh;
			margin-right: calc(var(--bs-gutter-x) * 0.5 * -1);
		}
	}
}
