/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
	padding-top: 16px;
	padding-bottom: 12px;
	font-family: $font-name-2;
	color: $color-brand-1;

	@include bp(large) {
		padding-top: 8px;
		padding-bottom: 13px;
	}

	&__logo {
		z-index: 4;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.29;
		text-align: center;
		font-feature-settings: 'tnum' on, 'lnum' on;
		padding: 25px 0 0 0;
		border-radius: 50px;
		text-decoration: none;

		&::before {
			content: '';
			display: block;
			position: absolute;
			border: 1px solid $color-brand-1;
			width: 36px;
			height: 36px;
			padding: 0;
			border-radius: 50px;
			opacity: 0;
			transition: $global-transition;
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		@include bp-down(large) {
			border: 1px solid $color-brand-1;
			width: 36px;
			height: 36px;
			padding: 0;

			&::before {
				display: none;
			}
		}
	}

	&__links {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;

		@include bp-down(large) {
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
		}

		.wpml-ls-item {
			margin-left: auto;
			margin-right: 0;
		}
	}

	&__nav {
		z-index: 3;
		width: 100%;

		@include bp-down(large) {
			background-color: $color-brand-2;
			display: block;
			position: absolute;
			top: -100%;
			left: 0;
			right: 0;
			padding: 19px 16px 51px 16px;
			transition: $global-transition;
		}

		&.active {
			top: 0;
		}
	}

	&__item {
		margin: 0 32px 0 0;

		@include bp-down(large) {
			margin: 0 0 22px 0;
		}
	}

	&__link {
		text-decoration: none;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.29;
		font-feature-settings: 'tnum' on, 'lnum' on;
		transition: $global-transition;

		@include bp-down(large) {
			font-size: 16px;
			line-height: 1.12;

			&::before {
				width: 100% !important;
			}
		}
	}

	&__hamburger {
		display: block;
		background: none;
		padding: 5px 0;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.12;

		@include bp(large) {
			display: none;
		}

		&--close {
			margin-bottom: 53px;
		}
	}
}
