.b-showroom {
	padding-bottom: 78px;

	@include bp(medium) {
		padding-bottom: 140px;
	}

	&__text {
		font-family: $font-name-2;
		font-style: normal;
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;
		font-feature-settings: 'tnum' on, 'lnum' on;
		color: $color-brand-1;
		margin: 0 0 58px 0;
	}
}
