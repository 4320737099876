/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

//  First
@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('~assets/fonts/CabinetGrotesk-Regular.woff2') format('woff2'),
		url('~assets/fonts/CabinetGrotesk-Regular.woff') format('woff');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('~assets/fonts/CabinetGrotesk-Medium.woff2') format('woff2'),
		url('~assets/fonts/CabinetGrotesk-Medium.woff') format('woff');
}

@font-face {
	font-family: $font-name;
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('~assets/fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
		url('~assets/fonts/CabinetGrotesk-Bold.woff') format('woff');
}

// Second
@font-face {
	font-family: $font-name-2;
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('~assets/fonts/Satoshi-Medium.woff2') format('woff2'),
		url('~assets/fonts/Satoshi-Medium.woff') format('woff');
}
