/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
	display: inline-block; /* [1] */
	vertical-align: middle; /* [2] */
	font: inherit; /* [3] */
	text-align: center; /* [4] */
	margin: 0; /* [4] */
	cursor: pointer; /* [5] */
	padding: 0.5rem 1rem;
	border: none;
	box-shadow: 0 0 0 1px transparent;
	transition: $global-transition;
	font-feature-settings: 'tnum' on, 'lnum' on;
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
	background-color: transparent;
	box-shadow: 0 0 0 1px $color-brand-1;
	color: $color-brand-1;
	font-family: $font-name-2;
	font-size: em(14);
	line-height: 1.29;
	font-weight: 500;
	padding: 12px 30px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none; /* [4] */
		box-shadow: 0 0 0 2px $color-brand-1;
	}

	&:hover,
	&:focus {
		// color: $color-white;
		outline-width: 2px;
	}
}

.c-btn--bigger {
	width: 100%;
}

.c-btn--ajax {
	.u-loader {
		display: none;
	}

	&.active {
		&:hover,
		&:focus {
			background-color: transparent;
		}

		.u-loader {
			display: block;
		}

		span {
			display: none;
		}
	}
}

/* Size variants
   ========================================================================== */

.c-btn--small {
	padding: 0.25rem 0.5rem;
}

.c-btn--large {
	padding: 0.75rem 1.5rem;
}
