.c-product-zoom {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: $color-brand-2;
	font-family: $font-name-2;
	color: $color-brand-1;
	font-feature-settings: 'tnum' on, 'lnum' on;
	padding: 13px 0;
	overflow: auto;
	width: 100%;
	height: 100%;

	.unactive {
		opacity: 0;
		transition: $global-transition;

		&.swiper-initialized {
			opacity: 1;
		}
	}

	&__swiper {
		height: 100vh;
	}

	&__container {
		height: 100%;
		overflow: auto;
	}

	&__close {
		font-weight: 400;
		line-height: 24px;
		width: 36px;
		height: 36px;
		border-radius: 50px;
		background: $color-brand-2;
		border: 1px solid transparent;
		transition: $global-transition;
		padding: 0;
		margin: 0 0 15px 0;

		&:hover {
			border-color: $color-brand-1;
		}

		@include bp-down(large) {
			border-color: $color-brand-1;
		}
	}

	&__desc {
		margin: 0;
	}

	&__title,
	&__desc {
		font-weight: 500;
		font-size: em(16);
		line-height: 1.5;

		@include bp(large) {
			margin: 14px 0 0 0;
		}
	}

	&__nav {
		background: $color-brand-2;
		width: 36px;
		height: 36px;
		border-radius: 50px;
		border: 1px solid transparent;
		padding: 0;

		&:hover {
			border-color: $color-brand-1;
		}

		&::after {
			content: none;
			display: none;
		}
	}
}

// .swiper {
// 	width: 600px;
// 	height: 300px;
// }
