.c-product {
	font-family: $font-name-2;
	color: $color-brand-1;
	font-weight: 500;
	font-feature-settings: 'tnum' on, 'lnum' on;
	margin: 0 0 64px 0;
	cursor: pointer;

	@include bp(medium) {
		margin: 0 0 66px 0;
	}

	&__image {
		background-color: $color-white;
		overflow: hidden;
		border: 30px solid $color-white;

		img {
			transition: all 2.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			object-fit: cover;
			width: 100%;
		}

		&:hover > img {
			transform: scale(1.4);
		}
	}

	&__image,
	&__desc {
		margin: 0 0 24px 0;
	}

	&__title,
	&__desc {
		font-size: em(16);
		line-height: 1.5;
	}

	&__status {
		font-size: 14px;
		line-height: 1.29;
		text-transform: lowercase;

		a {
			font-family: $font-name-2;
			font-weight: 500;
			font-size: 14px;
			line-height: 1.19;
			color: $color-brand-1;
			text-transform: capitalize;
			text-decoration: none;

			span {
				display: block;
				position: relative;
				margin-right: 16px;
				padding-bottom: 2px;

				&::before {
					width: 100%;
				}
			}

			svg {
				transition: $global-transition;
			}

			&:hover {
				span::before {
					width: calc(100% + 36px);
				}

				svg {
					transform: translateX(7px);
				}
			}
		}

		&--sprzedana {
			.c-product__title,
			.c-product__desc,
			.c-product__status {
				opacity: 0.5;
			}
		}
	}
}
