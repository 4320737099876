.u-loader-section {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: $color-brand-2;
	width: 100%;
	height: 100%;
	z-index: 50;
}

.u-loader {
	display: inline-block;
	position: relative;
	width: 70px;
	height: 17px;
	margin: 0 auto;
}

.u-loader div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 1px;
	background: $color-brand-1;
	animation: facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.u-loader div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}

.u-loader div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}

.u-loader div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}

@keyframes facebook {
	0% {
		top: 0;
		height: 30px;
	}

	50%,
	100% {
		top: 2px;
		height: 10px;
	}
}
