.u-border {
	&-top {
		padding-top: 47px;

		@include bp(medium) {
			padding-top: 44px;
		}

		&::before {
			content: '';
			width: 100%;
			height: 1px;
			background-color: $color-brand-1;
			display: block;
			transform: translateY(-47px);

			@include bp(medium) {
				transform: translateY(-44px);
			}
		}
	}

	&-lg {
		&-top {
			@include bp(large) {
				padding-top: 44px;
			}

			&::before {
				@include bp(large) {
					content: '';
					width: 100%;
					height: 1px;
					background-color: $color-brand-1;
					display: block;
					transform: translateY(-44px);
				}
			}
		}
	}
}
