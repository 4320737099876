// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */
$font-name: 'Cabinet Grotesk';
$font-name-2: 'Satoshi';
$font-sans-serif: $font-name, sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
	small: 480px,
	medium: 768px,
	large: 1024px,
	xlarge: 1280px,
	full: 1440px,
);

// $wrapper-paddings: 32px;
// $wrapper-width: 1440px + ($wrapper-paddings * 2);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-brand-1: #202020;
$color-brand-2: #fafafa;
$color-brand-3: #e5e5e5;

// Text
$color-bg: $color-white;
$color-text: $color-black;

// Links
$color-link: $color-medium-grey;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;

// Bootstrap GRID override
$grid-breakpoints: (
	xs: 0,
	sm: map-get($breakpoints, small),
	md: map-get($breakpoints, medium),
	lg: map-get($breakpoints, large),
	xl: map-get($breakpoints, xlarge),
	xxl: map-get($breakpoints, full),
);

$grid-columns: 12;
$grid-gutter-width: 24px;
// $grid-row-columns: 6;

$container-max-widths: (
	xxl: 1376px + $grid-gutter-width,
);
