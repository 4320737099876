.u-hover-border {
	position: relative;
	color: $color-brand-1;

	&::before {
		content: '';
		display: block;
		width: 0%;
		height: 1px;
		background-color: $color-brand-1;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		transition: $global-transition;
	}

	&:hover {
		&::before {
			width: 100%;
		}
	}

	&--show {
		&::before {
			width: 100%;
		}
	}
}
