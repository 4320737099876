/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
	&__nav {
		a {
			font-family: $font-name-2;
			font-style: normal;
			font-weight: 500;
			font-size: em(14);
			line-height: 1.29;
			font-feature-settings: 'tnum' on, 'lnum' on;
			color: $color-brand-1;
			text-decoration: none;
			display: block;
			margin: 0 0 8px 0;
			transition: $global-transition;

			@include bp-down(medium) {
				margin: 0 0 22px 0;

				&::before {
					width: 100%;
				}
			}
		}

		&--margin {
			margin-right: 31px;
		}

		&--mobile {
			@include bp-down(medium) {
				margin-bottom: 70px;
			}
		}
	}

	&__bottom {
		padding-top: 69px;
		padding-bottom: 27px;

		@include bp(medium) {
			padding-top: 140px;
		}
	}

	&__copy {
		font-family: $font-name-2;
		font-weight: 500;
		font-size: em(14);
		line-height: 1.71;
		font-feature-settings: 'tnum' on, 'lnum' on;
		color: $color-brand-1;

		span {
			@include bp-down(medium) {
				display: block;
			}
		}
	}

	&__back {
		width: 36px;
		height: 36px;
		border-radius: 50px;
		border: solid 1px $color-brand-1;
		transition: $global-transition;
		cursor: pointer;

		@include bp(medium) {
			border-color: transparent;

			&:hover {
				border-color: $color-brand-1;
			}
		}
	}
}
