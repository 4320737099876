.b-bio {
	padding-bottom: 90px;

	@include bp(medium) {
		padding-bottom: 172px;
	}

	&__photo {
		margin: 0 0 50px 0;

		@include bp(medium) {
			margin: 0;
		}
	}

	&__text {
		&,
		h3,
		p {
			color: $color-brand-1;
			font-weight: 500;
			font-style: normal;
			font-feature-settings: 'tnum' on, 'lnum' on;
		}

		h3 {
			font-weight: 500;
			font-size: em(42);
			margin: 0 0 50px 0;

			@include bp(medium) {
				margin: 0 0 40px 0;
			}
		}

		p {
			font-family: $font-name-2;
			font-size: em(16);
			margin: 0 0 24px 0;
			line-height: 1.5;

			&:last-of-type {
				margin: 0;
			}
		}
	}
}
