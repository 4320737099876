.c-heading {
	font-style: normal;
	font-weight: 500;
	font-size: em(42);
	line-height: 1.14;
	font-feature-settings: 'tnum' on, 'lnum' on;
	color: $color-brand-1;

	&--normal {
		margin: 0 0 37px 0;
	}

	&--large {
		margin: 0 0 54px 0;
	}
}
